.project-card{
    background: #17141d;;
    color: white;
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 350px;
    max-height: 450px !important;
    flex-shrink: 0;
    padding: 20px 20px;
    margin: 0px 15px;
    border-radius: 10px;
    box-shadow: -1rem 0 1.5rem #000;
    box-sizing: border-box;
    opacity: 0;
}

.project-card-intro {
    flex-basis: 20%;
}

.project-card-data {
    flex-grow: 1;
    flex-basis: 70%;
    overflow-y: scroll;
    scrollbar-width: none; 
}

.project-card-data::-webkit-scrollbar {
    display: none;
}

/* project location links */

.project-card-locations {
    flex-basis: 10%;
    display: flex;
    flex-direction: row;
    flex-grow: 0;
}

.project-card-locations > a {
    width: 30%;
    padding: 5px;
    cursor: pointer;
    text-align: center;
    font-size: 1.2em;
    border-radius: 5px;
    position: relative;
    transition: background-color 0.15s linear;
    color: white;
}

.project-card-locations > a:hover {
    background-color: #ad00ff;
    top: -1px;
}

.project-card-locations > a:active {
    top: 1px;
}

.project-card-locations > a > i{
    padding-right: 5px;
}

/* project into section */

.project-card-intro > h1 {
    margin-bottom: 5px;
}

.project-card-saperator {
    width: 100%;
    height: 5px;
    margin: 15px 0px;
    border-radius: 5px;
    background: rgb(173,0,255);
    background: linear-gradient(90deg, rgba(173,0,255,1) 0%, rgba(0,110,133,1) 100%, rgba(173,0,255,1) 100%);
}


@keyframes project-card-opacity-anim {
    0% {
        opacity: 0;
    }
    100% {
       opacity: 1;
    }
}

@media (min-width: 480px) and (max-width: 1024px) {

    .project-card {
        margin: 0px 10px;
        box-shadow: -1rem 0 1.2rem rgba(0,0,0,0.8);

    }
}

@media (min-width: 420px) and (max-width: 480px){

    .project-card {
        margin: 0px 10px;
        width: 300px;
        padding: 15px;
        box-shadow: -1rem 0 1.1rem rgba(0,0,0,0.7);
    }

    .project-card-locations > a {
        font-size: 1em;
    }

    .project-card-intro > h1 {
        font-size: 1.7em;
        margin-bottom: 0px;
    }

    .project-card-saperator {
        margin: 10px 0px;
    }

}


@media (min-width: 320px) and (max-width: 420px) {

    .project-card {
        margin: 0px 7px;
        width: 300px;
        padding: 12px;
        box-shadow: -1rem 0 1rem rgba(0,0,0,0.6);
    }

    .project-card-locations > a {
        font-size: 1em;
    }

    .project-card-intro > h1 {
        font-size: 1.75em;
        margin-bottom: 5px;
    }

    .project-card-saperator {
        margin: 10px 0px;
    } 
}

@media (max-width: 320px) {

    .project-card {
        margin: 0px 5px;
        width: 80%;
        padding: 15px;
        box-shadow: -1rem 0 1rem rgba(0,0,0,0.4);
    }

    .project-card-locations > a {
        font-size: 1em;
    }

    .project-card-intro > h1 {
        font-size: 1.5em;
        margin-bottom: 5px;
    }

    .project-card-saperator {
        margin: 8px 0px;
    } 
}

@media (max-width: 420px) and (max-height: 350px) {
    
    .project-card {
        margin: 0px 5px;
        width: 80%;
        padding: 8px;
        box-shadow: -1rem 0 1rem rgba(0,0,0,0.4);
    }

    .project-card-locations > a {
        font-size: 1em;
    }

    .project-card-intro > h1 {
        font-size: 1.3em;
        margin-bottom: 0px;
    }

    .project-card-saperator {
        margin: 8px 0px;
    } 
}

@media (max-height: 370px)  {
    .project-card {
        margin: 0px 5px;
        padding: 8px;
        box-shadow: -1rem 0 1rem rgba(0,0,0,0.4);
    }

    .project-card-locations > a {
        font-size: 0.9em;
    }

    .project-card-intro > h1 {
        font-size: 1.2em;
        margin-bottom: 0px;
    }

    .project-card-saperator {
        margin: 5px 0px;
    } 
}