.nav-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: 2em;
    z-index: 10;
    position: absolute;
    transform: rotate(90deg) translate(100%, -100%); 
    transform-origin: 100% 0;
    right: 2em;
}

.nav-container > a {
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    display: inline-block;
    width: 25%;
    color: white;
    text-align: center;
    text-transform: uppercase;
    font-size: 1.2em;
    padding: 10px 0px;
    cursor: pointer;
    user-select: none;
    letter-spacing: 0.2em;
    text-shadow: 4px 0px 6px rgba(0,0,0,0.8);
    outline: 0;
}

.nav-container > a.nav-item-active {
    font-weight: 500;
}

.nav-container > a.nav-item-active::after {
    display: block;
    width: 0%;
    background: white;
    position: relative;
    content: "";
    height: 2px;
    animation: navbar-active-animation cubic-bezier(0.165, 0.84, 0.44, 1) 0.7s forwards;
    margin: 0 auto;
}

@media screen and (max-width: 400px) {
    .nav-container > a { 
        font-size: 1em;
        padding: 5px 0px;
    }
}

@media screen and (max-height: 500px) {
    .nav-container > a { 
        font-size: 0.9em;
        letter-spacing: 0em;
        padding: 5px 0px;
        opacity: 0.8;
    }
}

@keyframes navbar-active-animation {
    0% {
        width: 0%;
    }
    100% {
        width: 80%;
    }
}