/* section animations, starts immediately */

.clip-animate .clip-section-bottom-to-top {
    animation: bottomToTop 1s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
}

.clip-animate .clip-section-top-to-bottom {
    animation: topToBottom 1s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
}

/* primary text animation, starts when section ends */

.clip-animate .clip-primary-bottom-to-top {
    animation: bottomToTop 1s cubic-bezier(0.165, 0.84, 0.44, 1) 0.7s forwards;
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
}

.clip-animate .clip-primary-left-to-right {
    animation: leftToRight 1s cubic-bezier(0.165, 0.84, 0.44, 1) 0.7s forwards;
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
}

.clip-animate .clip-primary-right-to-left {
    animation: rightToLeft 1s cubic-bezier(0.165, 0.84, 0.44, 1) 0.7s forwards;
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
}

.clip-animate .clip-primary-top-to-bottom {
    animation: topToBottom 1s cubic-bezier(0.165, 0.84, 0.44, 1) 0.7s forwards;
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
}

/* secondary text animations, starts after primary finished */

.clip-animate .clip-secondary-left-to-right {
    animation: leftToRight 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53) 1.2s forwards;
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
}

.clip-animate .clip-secondary-right-to-left {
    animation: rightToLeft 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53) 1.2s forwards;
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
}

.clip-animate .clip-secondary-top-to-bottom {
    animation: topToBottom 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) 1.2s forwards;
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
}

@keyframes bottomToTop {
    0% {
        clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
    }
    100% {
        clip-path: polygon(0 100%, 100% 100%, 100% 0, 0 0);
    }
}

@keyframes topToBottom {
    0% {
        clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    }
    100% {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
}

@keyframes leftToRight {
    0% {
        clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
    }
    100% {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
}

@keyframes rightToLeft {
    0% {
        clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
    }
    100% {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
}