
.brand-logos {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
}

.brand-logos > div {
    display: flex;
    flex-direction: column;
    margin: 0 20px;
}

.brand-logos > div > svg {
    width: 2.5em;
    margin: 0 auto;
}


@media (min-width: 340px) and (max-width: 500px) {

    .brand-logos > div {
        margin: 0 10px;
    }

    .brand-logos > div > svg {
        width: 2em;
        margin: 0 auto;
    }

    .brand-logos > div > span {
        font-size: 0.9em;
    }
}

@media (max-width: 340px) {
  
    .brand-logos > div {
        margin: 20px 10px;
    }

    .brand-logos > div > svg {
        width: 2em;
        margin: 0 auto;
    }

    .brand-logos > div > span {
        display: none;
    }

}

@media (max-height: 450px) {
  
    .brand-logos > div {
        margin: 5px 10px;
    }

    .brand-logos > div > svg {
        width: 2em;
        margin: 0 auto;
    }

    .brand-logos > div > span {
        display: none;
    }

}