#contact-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    border-radius: 5px;
}

#contact-form > label {
    display: none;
}

#contact-form > input:nth-of-type(1) {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

#contact-form input,
textarea {
    width: 100%;
    background: rgba(255, 255, 255, 0.212);
    border: none;
    transition: border 0.2s linear;
    box-sizing: border-box;
    border: 1px solid black;
    padding: 7px;
    margin: 5px 0px;
    border-radius: 5px;
}

#contact-form input:focus,
textarea:focus {
    outline: none;
    border: 1px solid #fdf535;
}

#contact-form > button {
    background: #fdf535;
    width: 100%;
    height: 40px;
    color: black;
    border: none;
    position: relative;
    right: 0;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
    margin: 5px 0px;
    transition: all 0.2s linear;
}

#contact-form > button > i {
    color: black;
    font-size: 2.5em;
}

#contact-form button:active {
    top: 1px;
}

#form-processing {
    animation: animateLoading 1s linear infinite;
}

.submit-error {
    background: red !important;
}

.submit-success{
    background: #5baa00 !important;
}

.submit-error > span {
    color: white;
    font-size: 1.1em;
}

@keyframes animateLoading {
    0% {
        transform: rotateZ(0deg);
    }
    100% {
        transform: rotateZ(360deg);
    }
}

@media (min-height: 330px) and (max-height: 500px) {
    #contact-form > input,
    #contact-form > textarea {
        padding: 4px;
        margin: 3px 0px;
    }

    #contact-form > button {

        margin: 3px 0px;
    }

    #contact-form > button > i {
        font-size: 6vh;
    }
}

@media (max-height: 330px) {
    #contact-form > input,
    #contact-form > textarea {
        padding: 2px;
        margin: 2px 0px;
    }



    #contact-form > button {
        height: 35px;
        margin: 2px 0px;
    }

    #contact-form > button > i {
        font-size: 6vh;
    }
}
