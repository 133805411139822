* {
  margin: 0;
  padding: 0;
  font-family: 'Raleway', sans-serif;
}

html, body {
  height: 100%;
  width: 100%;
  overscroll-behavior: contain;
  overflow: hidden;
}

#root {
  height: 100%;
  width: 100%; 
}

a {
  text-decoration: none;
}